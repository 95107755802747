import React, { useState } from "react";
import apiAxios from "../../services/api"

import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";

const Signup = ({ history }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const signupResponse = await apiAxios.post("/auth/signup/admin", { name, email, password, role, whatsapp });
      if (signupResponse.data === "Email já cadastrado") setError("Email já cadastrado")
      else if (signupResponse.data === "Telefone já cadastrado") setError("Telefone já cadastrado")
      else {
        setEmail('')
        setName('')
        setWhatsapp('')
        setPassword('')
      }
    } catch (err) {
      console.log(err);
      setError("Ocorreu um erro ao registrar sua conta.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        <div className='container-fluid m-3 pb-3 routes'>
          <div className="d-flex flex-row align-items-center">
            <h1>Criar nova Conta</h1>
          </div>
          <hr></hr>
          <div className="container d-flex justify-content-center align-item-center">
            <form
              className="d-flex flex-column mt-5 pb-50 w-50"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="p-3">
                <div className="form-group">
                  <input
                    name="name"
                    type="string"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Nome'
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='E-mail'
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Senha'
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    name="whatsapp"
                    type="whatsapp"
                    className="form-control"
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                    placeholder='Whatsapp'
                    required
                  />
                </div>
                <div className="form-group">
                  <select name="role" className="form-control" id="roleInput" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="admin">Admin</option>
                    <option value="attendant">Tratador</option>
                    <option value="client">Cliente</option>
                  </select>
                </div>
                <div className="d-flex justify-content-center align-item-center py-3">
                  <button type="submit" className="btn btn-primary  w-100">
                    Cadastrar
            </button>
                </div>
                {error && <p className="text-center text-danger">{error}</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>



  );
};

export default Signup;
