import React from 'react';
import Navbar from '../../components/Navbar';

const Terms = () => (
  <div>
    <Navbar logoutBtn={false}/>
    <div className='m-5 routes'>
      <h3 className='text-primary text-center mb-3'>Termo de Uso Aplicativo</h3>
      <div>
        <p>A Sigura Water está muito feliz com sua visita em nosso aplicativo da hth e agradece seu interesse em nossa empresa. <br/>
          Este App visa fornecer informações aos seus usuários, dentre eles consumidores, lojistas, tratadores de piscinas, a respeito de produtos químicos e tratamentos da água da piscina. <br/>
          Este termo de uso fornece informações importantes para você utilizar o nosso App de maneira fácil e segura, e ao aceitar esse termo de uso, você concorda com os termos a seguir previstos.</p>
      </div>
      <div>
        <h5>Cadastro e acessos</h5>
        <p>Para acessar todas as funcionalidades do App, quando de seu primeiro acesso você deverá realizar um cadastro, indicando inicialmente que tipo de usuário você representa: se consumidor ou tratador de piscinas. Feita esta indicação você deverá imputar os dados solicitados para tal cadastros, a partir do qual serão gerados login e senha. Uma vez concluído seu cadastro você poderá acessar seu aplicativo sempre que quiser, mediante login e senha.</p>
      </div>
      <div>
        <h5>Funcionalidades e ferramentas</h5>
        <p>Por meio deste App, você terá acesso as seguintes ferramentas.
          <br/>
          - Calculo de dosagem de produto
          <br/>
          - Como tratar a água da piscina
          <br/>
          - Avaliação dos parâmetros da água com a utilização da hth fita teste
          <br/>
          - Informações de produto
          <br/>
          - Onde comprar os produtos hth
          <br/>
          - Conteúdo relacionados ao mundo da piscina, desde de decorações até tratamento químico
          <br/>
          <br/>
          Ao usuário tratador de piscinas, serão ainda disponibilizadas as seguintes ferramentas:
          <br/>
          - Agenda para controle de visitas aos clientes
          <br/>
          - Envio de orçamentos de serviços para potenciais clientes
          <br/>
          - Controle financeiro do seu dia a dia de trabalho</p>
      </div>
      <div>
        <h5>Responsabilidade do usuário</h5>
        <p>Ao aceitar estes Termos de Uso, você usuário se compromete a utilizar o App somente para as finalidades e nos termos previstos nestes Termos de Uso e, em especial, a não utilizar o App para quaisquer finalidades ilícitas, discriminatórias e/ou contrárias a moral e aos bons costumes.
        <br/>
        O usuário, consumidor ou tratador profissional será o único responsável por quaisquer danos e/ou prejuízos que suas ações e/ou omissões relacionadas ao uso do App possam causar a quaisquer terceiros, devendo indenizar a Sigura Water por quaisquer danos e prejuízos, custos e/ou despesas que essa venha sofrer ou incorrer em decorrência de qualquer ação ou reclamação que venha a ser contra ela imputada em decorrência e/ou relacionada ao uso do App realizado pelo usuário.</p>
      </div>
      <div>
        <h5>Limite de Responsabilidade Legal</h5>
        <p>Em nenhuma circunstância a Sigura Water terá qualquer obrigação para com você por qualquer perda ou dano de qualquer tipo (incluindo entre outros por qualquer perda ou dano direto, indireto, econômico, exemplar, especial, punitivo, acidental ou consequencial) que esteja direta ou indiretamente relacionada ao App, seu conteúdo, uso, impossibilidade de uso, ou desempenho; qualquer ação tomada relativa a uma investigação pela Sigura Water ou autoridades competentes em relação ao seu uso do App ou por outra parte; qualquer ação tomada relativa a proprietários de direitos autorais ou outros direitos de propriedade intelectual; qualquer erro ou omissão na operação do App, ou qualquer dano ao computador ou dispositivo móvel ou outro equipamento de qualquer usuário ou tecnologia, incluindo entre outros danos, em função de falhas de segurança ou de qualquer tipo de vírus, bugs, falsificação, fraude, erro, comissão, interrupção, defeito, atraso na operação ou transmissão, falha na linha ou rede de computadores ou qualquer outro tipo de problema técnico ou funcionamento incorreto, incluindo entre outros, danos por perda de lucros, perda de credibilidade, perda de dados, interrupção do trabalho, precisão de resultados, ou falha ou funcionamento incorreto do computador.</p>
      </div>
      <div>
        <h5>Direitos da Sigura Water</h5>
        <p>A Sigura Water é exclusiva titular e/ou licenciada dos direitos de propriedade intelectual sobreo e/ou relacionados ao App bem como sobre a marca hth, sendo que o uso de quaisquer direitos por quaisquer terceiros, inclusive usuários do App dependerá sempre de uma aprovação prévia, por escrito, da Sigura Water.
        <br/>
        Reservamo-nos o direito de implementar quaisquer alterações no App e/ou nestes Termos de Uso, e/ou cancelar o App por completo, a qualquer momento, sem que se faça necessária sua autorização prévia para tanto, sendo que a versão mais recente e autorizada destes Termos de Uso ficará sempre disponível para acesso no App.</p>
      </div>
      <div>
        <h5>Dados</h5>
        <p>Para a Sigura Water a proteção de dados é uma prioridade. Todos os seus dados coletados por meio de seu cadastro e/ou uso do App permanecerão em sigilo e serão tratados de forma segura, conforme os termos da nossa política de Privacidade, acessível por meio do nosso aplicativo.</p>
      </div>
      <div>
        <h5>Transferência</h5>
        <p>É proibida a venda, transferência, licenciamento ou cessa da sua conta, de seu nome de usuário, ou qualquer outro direito relacionado à sua conta a quaisquer terceiros.</p>
      </div>
      <div className='mb-5 pb-5'>
        <h5>Contato</h5>
        <p className='mb-5 pb-5'>Caso tenha alguma dúvida ou sugestão, ou queira realizar qualquer ajuste ou o cancelamento de sua conta, entre em contato com a hth que teremos o maior prazer em ajudá-lo.</p>   
      </div>
    </div>
  </div>
);

export default Terms;