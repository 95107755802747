import React, { useState, useEffect, useCallback } from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";

const EditNews = (props) => {
  const [category, setCategory] = useState("management");
  const [imageUrl, setImageUrl] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [longTitle, setLongTitle] = useState("");
  const [description, setDescription] = useState("");
  const [group, setGroup] = useState("both");
  const [text, setText] = useState("");
  const [highlight, setHighlight] = useState(false);
  const [postThen, setPostThen] = useState(false);
  const [postCatch, setPostCatch] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getNew = useCallback(() => {
    const { id } = props.match.params;

    api
      .get(`/api/news/${id}`)
      .then(({ data }) => {
        // console.log('news',data)
        setCategory(data.category);
        setImageUrl(data.image);
        setShortTitle(data.shortTitle);
        setLongTitle(data.longTitle);
        setDescription(data.description);
        setGroup(data.group);
        setText(data.text);
        setLoading(false);
        data.highlight === "true" ? setHighlight(true) : setHighlight(false);
      })
      .catch((error) => console.log(error));
  }, [props.match.params]);

  useEffect(() => {
    getNew();
  }, [getNew]);

  const updateNew = (e) => {
    e.preventDefault();
    const { id } = props.match.params;

    if (!imageUrl) {
      setImgError("Insira uma imagem");
      return;
    }

    api
      .patch(`/api/news/edit/${id}`, {
        category,
        image: imageUrl,
        shortTitle,
        longTitle,
        description,
        group,
        text,
        highlight,
      })
      .then(() => setPostThen(true))
      .catch((error) => {
        setPostCatch(true);
        console.log(error);
      });
  };

  const handleFileChange = (event) => {
    const uploadData = new FormData();
    uploadData.append("imageUrl", event.target.files[0]);

    api
      .post("/api/news/upload", uploadData)
      .then((response) => {
        setImageUrl(response.data.secure_url);
        setOriginalName(response.data.originalName);
      })
      .catch((error) => console.log(error));
  };

  const handleCategory = (value) => {
    if (value === "management") {
      setCategory("management");
      setGroup("attendant");
    } else {
      setCategory(value);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 pb-3 routes">
            <h2>Editar conteúdo</h2>
            {postCatch && (
              <p className="text-danger">Não foi possível editar conteúdo.</p>
            )}
            {imgError && <p className="text-danger text-center">{imgError}</p>}
            <hr></hr>
            <form onSubmit={(e) => updateNew(e)} encType="multipart/form-data">
              <div>
                <div className="form-group">
                  <label>Título curto</label>
                  <input
                    type="text"
                    className="form-control"
                    name="shortTitle"
                    maxLength="40"
                    required="true"
                    value={shortTitle}
                    onChange={(e) => setShortTitle(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Título Interna</label>
                  <input
                    type="text"
                    className="form-control"
                    name="longTitle"
                    required="true"
                    value={longTitle}
                    onChange={(e) => setLongTitle(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Descrição</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    maxLength="90"
                    required="true"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Imagens</label>
                  <input
                    type="file"
                    className="form-control"
                    name="imageUrl"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">
                      Aguarde enquanto a imagem carrega:{" "}
                    </span>
                  </span>
                  <span className="file-name">
                    {imageUrl && (
                      <div className="preview">
                        <div className="preview__card" key={originalName}>
                          <img
                            className="preview__img"
                            src={imageUrl}
                            alt="product"
                          />
                          <button
                            className="btn__icon"
                            type="button"
                            onClick={() => setImageUrl(null)}
                          >
                            <img src="/images/trash.png" alt="trash" />
                          </button>
                        </div>
                      </div>
                    )}
                  </span>
                </div>
                <div className="form-group">
                  <label>Texto</label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="text"
                    value={text}
                    rows="10"
                    required="true"
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end">
                <div className="form-group col-md-3 my-4">
                  <label>
                    <input
                      type="checkbox"
                      value={highlight}
                      checked={highlight}
                      onChange={(e) => setHighlight(e.target.checked)}
                    />{" "}
                    Destaque{" "}
                  </label>
                </div>
                <div className="form-group col-md-3">
                  <label>Categoria</label>
                  <select
                    type="text"
                    className="form-control"
                    name="category"
                    value={category}
                    onChange={(e) => handleCategory(e.target.value)}
                  >
                    <option value="summer">Verão hth®</option>
                    <option value="winter">Inverno hth®</option>
                    <option value="health">Saúde hth®</option>
                    <option value="purify">Purificação hth®</option>
                    <option value="management">Gestão hth®</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label>Destinatário</label>
                  <select
                    type="text"
                    className="form-control"
                    name="group"
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                  >
                    <option value="both">Tratador e cliente</option>
                    <option value="attendant">Tratador</option>
                    <option value="client">Cliente</option>
                  </select>
                </div>
              </div>
              <button className="btn btn-primary mb-5" type="submit">
                Salvar
              </button>
              <Link to={"/news"} className="btn btn-info ml-3 mb-5">
                Voltar
              </Link>
              {postThen && <Redirect to={"/news"} />}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditNews;
