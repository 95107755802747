import React, { useState, useEffect, useCallback } from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";

const EditProduct = (props) => {
  const [title, setTitle] = useState("");
  const [expiration, setExpiration] = useState("");
  const [type, setType] = useState("");
  const [operation, setOperation] = useState("");
  const [packages, setPackages] = useState("");
  const [link, setLink] = useState("");
  const [shop_link, setShop_Link] = useState("");
  const [loading, setLoading] = useState(true);
  const [dosage, setDosage] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [setOriginalName] = useState("");
  const [updateThen, setUpdateThen] = useState(false);
  const [updateCatch, setUpdateCatch] = useState(false);

  const getProduct = useCallback(() => {
    const { id } = props.match.params;

    api
      .get(`/api/products/${id}`)
      .then(({ data }) => {
        // console.log('products',data)
        setTitle(data.title);
        setExpiration(data.expiration);
        setType(data.type);
        setOperation(data.operation);
        setPackages(data.packages);
        setLink(data.link);
        setShop_Link(data.shop_link);
        setImageUrl(data.images);
        setDosage(data.dosage);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [props.match.params]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const updateProduct = (e) => {
    e.preventDefault();
    const { id } = props.match.params;

    api
      .patch(`api/products/edit/${id}`, {
        title,
        expiration,
        type,
        operation,
        packages,
        link,
        shop_link,
        dosage,
        images: [...imageUrl],
      })
      .then(() => setUpdateThen(true))
      .catch((error) => {
        setUpdateCatch(true);
        console.log(error);
      });
  };

  const handleFileChange = (event) => {
    const uploadData = new FormData();
    uploadData.append("imageUrl", event.target.files[0]);

    api
      .post("/api/products/upload", uploadData)
      .then((response) => {
        const images = [...imageUrl, response.data.secure_url];
        setImageUrl(images);
        setOriginalName(response.data.originalName);
      })
      .catch((error) => console.log(error));
  };
  // const handleDosageField = (name, target) => {
  //   let newDosage = dosage[0];
  //   newDosage[name] = target;

  //   setDosage([newDosage]);
  //   console.log(dosage);
  // }

  const handleDosageField = (id, name, target) => {
    if (dosage.some((item) => item.id === id)) {
      let newDosage = dosage;
      newDosage.map((item) => {
        if (item["id"] === id) {
          item[name] = name === "dosage" ? +target : target;
        }
        return 0;
      });
      setDosage(newDosage);
      console.log(dosage);
      return;
    }
    let newDosage = {};
    newDosage["id"] = id;
    newDosage[name] = name === "dosage" ? +target : target;
    console.log(newDosage);
    setDosage([...dosage, newDosage]);
    console.log(dosage);
  };

  const deleteImage = (idx) => {
    const del = [...imageUrl];
    del.splice(idx, 1);

    setImageUrl(del);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 routes">
            <h2> Editar produto</h2>
            {updateThen && <Redirect to={"/products"} />}
            {updateCatch && "Não foi possível atualizar o produto"}
            <hr></hr>
            <form
              encType="multipart/form-data"
              onSubmit={(e) => updateProduct(e)}
            >
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Nome do produto</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputTitle"
                    name="title"
                    required="true"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Validade</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputExpiration"
                    required="true"
                    name="expiration"
                    onChange={(e) => setExpiration(e.target.value)}
                    value={expiration}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Tipo</label>
                  <select
                    id="inputTypeEdit"
                    name="type"
                    className="form-control"
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                  >
                    <option value="rate">Passo 1 Avaliar</option>
                    <option value="purify">Passo 2 Purificar</option>
                    <option value="protect">Passo 3 Proteger</option>
                    <option value="solutions">Passo 4 Soluções</option>
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-3">
                  <label>Dosagem</label>
                  <input
                    type="number"
                    className="form-control"
                    name="dosage"
                    onChange={(e) =>
                      handleDosageField(1, "dosage", e.target.value)
                    }
                    placeholder={dosage.length > 0 ? dosage[0].dosage : ""}
                    step="0.01"
                    min="0"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Unidade</label>
                  <select
                    name="measure"
                    className="form-control"
                    onChange={(e) =>
                      handleDosageField(1, "units", e.target.value)
                    }
                    value={dosage.length > 0 ? dosage[0].units : ""}
                  >
                    <option value="dafault" disabled selected hidden>
                      Escolha a unidade
                    </option>
                    <option value="g">gramas</option>
                    <option value="ml">mililitros</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label>Condição</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    onChange={(e) =>
                      handleDosageField(1, "description", e.target.value)
                    }
                    placeholder={dosage.length > 0 ? dosage[0].description : ""}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-3">
                  <label>Dosagem</label>
                  <input
                    type="number"
                    className="form-control"
                    name="dosage"
                    onChange={(e) =>
                      handleDosageField(2, "dosage", e.target.value)
                    }
                    placeholder={dosage.length === 2 ? dosage[1].dosage : ""}
                    step="0.01"
                    min="0"
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Unidade</label>
                  <select
                    name="measure"
                    className="form-control"
                    onChange={(e) =>
                      handleDosageField(2, "units", e.target.value)
                    }
                    value={dosage.length === 2 ? dosage[1].units : ""}
                  >
                    <option value="" disabled selected hidden>
                      Escolha a unidade
                    </option>
                    <option value="g">gramas</option>
                    <option value="ml">mililitros</option>
                  </select>
                </div>
                <div className="form-group col-md-3">
                  <label>Condição</label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    onChange={(e) =>
                      handleDosageField(2, "description", e.target.value)
                    }
                    placeholder={
                      dosage.length === 2 ? dosage[1].description : ""
                    }
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Atuação</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputOperation"
                  rows="2"
                  name="operation"
                  required="true"
                  onChange={(e) => setOperation(e.target.value)}
                  value={operation}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Disponivel em</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPackage"
                  name="packages"
                  required="true"
                  onChange={(e) => setPackages(e.target.value)}
                  value={packages || ""}
                />
              </div>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <label>Link do produto</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAlert"
                    name="link"
                    required="true"
                    onChange={(e) => setLink(e.target.value)}
                    value={link || ""}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-8">
                  <label>Link do produto na loja</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAlert"
                    name="shop_link"
                    required="true"
                    onChange={(e) => setShop_Link(e.target.value)}
                    value={shop_link || ""}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Imagens</label>
                {imageUrl.length < 2 && (
                  <>
                    <input
                      type="file"
                      className="form-control"
                      id="inputimage"
                      name="imageUrl"
                      onChange={(e) => handleFileChange(e)}
                    />
                    <span className="file-cta">
                      <span className="file-icon">
                        <i className="fas fa-upload"></i>
                      </span>
                      <span className="file-label">
                        Aguarde enquanto a imagem carrega:{" "}
                      </span>
                    </span>
                  </>
                )}
                <span className="file-name">
                  {/* {originalName && originalName} */}
                  <div className="preview">
                    {imageUrl.length > 0 &&
                      imageUrl.map((image, idx) => (
                        <div className="preview__card" key={idx}>
                          <img
                            className="preview__img"
                            src={image}
                            alt="product"
                          />
                          <button
                            className="btn__icon"
                            type="button"
                            onClick={() => deleteImage(idx)}
                          >
                            <img src="/images/trash.png" alt="trash" />
                          </button>
                        </div>
                      ))}
                  </div>
                </span>
              </div>
              <button className="btn btn-primary mb-5">Salvar</button>
              <Link to={"/products"} className="btn btn-info ml-3 mb-5">
                Voltar
              </Link>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProduct;
