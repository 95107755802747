import React, { useState } from "react";
import apiAxios from "../../services/api";

const CreatePassword = ({ history, match }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [sucess, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("As senhas devem ser iguais, tente novamente!");
      return;
    }
    try {
      await apiAxios.post(`/api/forgotPassword/${match.params.id}`, {
        password,
      });
      setSuccess("Senha alterada com sucesso!");
      setPassword("");
      setConfirmPassword("");
    } catch (err) {
      setError("Email ou senha incorretos.");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-item-center">
      <form
        className="d-flex flex-column mt-5 pb-50 w-50"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="border border-secondary p-3 rounded w-100">
          <h4 className="text-primary text-center py-3">Recupere sua senha</h4>
          <div className="form-group mx-5">
            <input
              type="password"
              name="password"
              className="form-control"
              aria-describedby="emailHelp"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Nova senha"
              required
            />
          </div>
          <div className="form-group mx-5">
            <input
              name="confirmPassword"
              type="password"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              placeholder="Confirme sua nova senha"
              required
            />
          </div>
          <div className="d-flex justify-content-center align-item-center py-3">
            <button type="submit" className="btn btn-primary mx-5 w-100">
              Entrar
            </button>
          </div>
          {error && <p className="text-center text-danger">{error}</p>}
          {sucess && <p className="text-center text-success">{sucess}</p>}
        </div>
      </form>
    </div>
  );
};

export default CreatePassword;
