import React, { useState, useCallback, useEffect } from "react";
import { Link} from "react-router-dom";
import api from "../../../services/api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";

import ReactExport from "react-export-excel";

import { parseISO, format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Loader from "../../../components/Loader";
import { replaceSpecialChars } from "../../../../src/helper";

import CsvDownloader from 'react-csv-downloader';
import moment from 'moment';
import 'moment/locale/pt-br';
var flatten = require('flat')

const Users = () => {
  const [users, setUsers] = useState([]);
  const [renderUser, setRenderUser] = useState([]);
  const [del, setDel] = useState(false);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(true);

  const [totalUsers, setTotalUsers] = useState();
  const [adminUsers, setAdminUsers] = useState();
  const [attendantUsers, setAttendantUsers] = useState();
  const [clientUsers, setClientUsers] = useState();

  const [userType, setUserType] = useState("");
  const [period, setPeriod] = useState("");
  const [months, setMonths] = useState([]);
  const [state, setState] = useState("");

  const [exportableUsers, setExportableUsers] = useState([]);

  let current_date = new Date().toJSON().slice(0, 10);

  const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  const [endDate, setEndDate] = useState(new Date("2014/02/10"));

  const [earliestDate, setEarliestDate] = useState(new Date("2014/02/10"));
  const [latestDate, setLatestDate] = useState(new Date("2014/02/10"));

  const columns = [
    {
      id: 'name',
      displayName: 'Nome',
    },
    {
      id: 'role',
      displayName: 'app_role',
    },
    {
      id: 'email',
      displayName: 'E-mail',
    },
    {
      id: 'whatsapp',
      displayName: 'Telefone',
    },
    {
      id: 'address.street',
      displayName: 'Endereço',
    },
    {
      id: 'address.addressNumber',
      displayName: 'Número',
    },
    {
      id: 'address.city',
      displayName: 'Cidade',
    },
    {
      id: 'address.state',
      displayName: 'Estado',
    },
    {
      id: 'address.zip',
      displayName: 'CEP',
    },
    {
      id: 'createdAt',
      displayName: 'app_user_created',
    },
    {
      id: 'updatedAt',
      displayName: 'app_user_modified',
    },
    {
      id: 'news.acceptance',
      displayName: 'app_optin',
    },
    {
      id: 'news.updatedAt',
      displayName: 'app_optin_time',
    },
    {
      id: 'pools.0.title',
      displayName: 'Piscina 1',
    },
    {
      id: 'pools.0.capacity',
      displayName: 'Piscina 1 — capacidade',
    },
    {
      id: 'pools.0.description',
      displayName: 'Piscina 1 — material',
    },

    {
      id: 'pools.1.title',
      displayName: 'Piscina 2',
    },
    {
      id: 'pools.1.capacity',
      displayName: 'Piscina 2 — capacidade',
    },
    {
      id: 'pools.1.description',
      displayName: 'Piscina 2 — material',
    },

    {
      id: 'pools.2.title',
      displayName: 'Piscina 3',
    },
    {
      id: 'pools.2.capacity',
      displayName: 'Piscina 3 — capacidade',
    },
    {
      id: 'pools.2.description',
      displayName: 'Piscina 3 — material',
    },

    {
      id: 'pools.3.title',
      displayName: 'Piscina 4',
    },
    {
      id: 'pools.3.capacity',
      displayName: 'Piscina 4 — capacidade',
    },
    {
      id: 'pools.3.description',
      displayName: 'Piscina 4 — material',
    },

    {
      id: 'pools.4.title',
      displayName: 'Piscina 5',
    },
    {
      id: 'pools.4.capacity',
      displayName: 'Piscina 5 — capacidade',
    },
    {
      id: 'pools.4.description',
      displayName: 'Piscina 5 — material',
    },

    {
      id: 'pools.5.title',
      displayName: 'Piscina 6',
    },
    {
      id: 'pools.5.capacity',
      displayName: 'Piscina 6 — capacidade',
    },
    {
      id: 'pools.5.description',
      displayName: 'Piscina 6 — material',
    },

    {
      id: 'pools.6.title',
      displayName: 'Piscina 7',
    },
    {
      id: 'pools.6.capacity',
      displayName: 'Piscina 7 — capacidade',
    },
    {
      id: 'pools.6.description',
      displayName: 'Piscina 7 — material',
    },

    {
      id: 'pools.7.title',
      displayName: 'Piscina 8',
    },
    {
      id: 'pools.7.capacity',
      displayName: 'Piscina 8 — capacidade',
    },
    {
      id: 'pools.7.description',
      displayName: 'Piscina 8 — material',
    },

    {
      id: 'pools.8.title',
      displayName: 'Piscina 9',
    },
    {
      id: 'pools.8.capacity',
      displayName: 'Piscina 9 — capacidade',
    },
    {
      id: 'pools.8.description',
      displayName: 'Piscina 9 — material',
    },

    {
      id: 'pools.9.title',
      displayName: 'Piscina 10',
    },
    {
      id: 'pools.9.capacity',
      displayName: 'Piscina 10 — capacidade',
    },
    {
      id: 'pools.9.description',
      displayName: 'Piscina 10 — material',
    },
  ]

  const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const getMonthsOnce = useCallback(() => {
    api
      .get("/api/users")
      .then((response) => {
        setLoading(false);
        const newData = response.data.map((item) => {
          item.delAlert = false;
          return item;
        });
      //  setUsers(newData);
       // setRenderUser(newData);
        setTotalUsers(newData.length);
        setAdminUsers(newData.filter(x => x.role =="admin").length);
        setAttendantUsers(newData.filter(x => x.role =="attendant").length);
        setClientUsers(newData.filter(x => x.role =="client").length);

        let flatArray = newData.map((x) => {
          return flatten(x)
      })

      let arrayExportableUsers = JSON.parse(
        JSON.stringify(flatArray).replaceAll('true', '1')
      );

    setExportableUsers(arrayExportableUsers);

      setUsers(newData);
      setRenderUser(newData);

   //   console.log(flatArray[6])
             
       let monthsArr = flatArray.map(v => (moment(v.createdAt).format("YYYY-MM-DD") ));

       //console.log(monthsArr)
       
       let monthsUnique = [];
       
       monthsArr.forEach(function(item) {
          if(monthsUnique.indexOf(item) < 0) {
           monthsUnique.push(item);
          }
       });
    //   console.log(monthsUnique); // That's what you are

       let monthsSorted  = monthsUnique.sort((a,b) => new moment(a.date).format('YYYY-MM-DD') - new moment(b.date).format('YYYY-MM-DD'))

     //  console.log(monthsSorted)

       setEarliestDate(monthsSorted[0])
       setLatestDate(monthsSorted[monthsSorted.length - 1])

       setStartDate(new Date(monthsSorted[0]))
       setEndDate(new Date(monthsSorted[monthsSorted.length - 1]))
       
         //  setMonths(monthsUnique);
     
      })
      .catch((error) => console.log(error));
  }, []);

  const getUsers = useCallback((type, time, state) => {
    api
      .get("/api/users")
      .then((response) => {
        setLoading(false);
        let newData = response.data.map((item) => {
          item.delAlert = false;
          return item;
        });

        let flatArray = newData.map((x) => { return flatten(x) })

        let arrayExportableUsers = JSON.parse(
          JSON.stringify(flatArray).replaceAll('true', '1')
        );

      setExportableUsers(arrayExportableUsers);

      console.log("type: ", type)
      console.log("time: ", time)
 
      if (type !== "" && type !== undefined){
        let filterByType = newData.reduce(function(result, item) {
         if (item.role === type) {
           result.push(item);
         }
         return result;
       }, []);
             
       newData = filterByType.flat(); 
       }

       if (time !== "" &&  time !== undefined){

        let filterByPeriod;

          filterByPeriod = newData.reduce(function(result, item) {

          time.forEach((day) => {
            if (moment(day).isSame(item.createdAt, 'day')) {
              result.push(item);
            }
          });

            return result;
          }, []);
    

           newData = filterByPeriod.flat() 
           console.log("Usuários filtrados ")
           console.log(newData)
     
         }

         setUsers(newData);
         setRenderUser(newData);

         let arrayExportableUsers2 = JSON.parse(
          JSON.stringify(newData).replaceAll('true', '1')
        );

      setExportableUsers(arrayExportableUsers2);

         setTotalUsers(newData.length);
        setAdminUsers(newData.filter(x => x.role =="admin").length);
        setAttendantUsers(newData.filter(x => x.role =="attendant").length);
        setClientUsers(newData.filter(x => x.role =="client").length);

      })
      .catch((error) => console.log(error));
  }, []);

  const handleType = (e) => {
    setUserType(e.target.value)   
    getUsers(e.target.value, period, state);
  }

  const handlePeriod = (e) => {   

console.log(e);
console.log(e.startDate);
console.log(e.endDate);

var dates = [];

if ( e.startDate != undefined){
  var currDate = moment(e.startDate).startOf('day');
} else {
  var currDate = moment(startDate).startOf('day');
}

if ( e.endDate != undefined){
  var lastDate = moment(e.endDate).startOf('day');
} else {
  var lastDate = moment(endDate).startOf('day');
}

while(currDate.add(1, 'days').diff(lastDate) < 0) {
  //  console.log(currDate.toDate());
    dates.push(currDate.clone().toDate());
}

console.log(dates);

    setPeriod(dates)   
    getUsers(userType, dates, state);
  //  console.log(typeof e.target.value)
  //  console.log(e.target.value)
  }

  useEffect(() => {
    getMonthsOnce();
  }, [getMonthsOnce]);

  const toggleDetail = (idx) => {
    const copyUsers = [...renderUser];
    copyUsers[idx].toggle = !copyUsers[idx].toggle;
    setRenderUser(copyUsers);
  };

  const showAlert = (idx) => {
    const temp = renderUser.map((item, i) => {
      if (idx === i) {
        item.delAlert = !item.delAlert;
      }
      return item;
    });
    setRenderUser(temp);
  };

  const deleteUsers = (id) => {
    api
      .delete(`/api/users/delete/${id}`)
      .then(() => {
        setDel(true);
        getUsers();
      })
      .catch((err) => console.log(err));
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setInput(value.toLowerCase());
    const search = replaceSpecialChars(value.toLowerCase());
    let regex = new RegExp(search, "gi");

    const filteredUsers = users.filter(
      (user) =>
        user.name && regex.test(replaceSpecialChars(user.name.toLowerCase()))
    );
    setRenderUser(filteredUsers);
  };

  const timeFormatted = (date) =>
    format(zonedTimeToUtc(parseISO(date), "America/Sao_Paulo"), "dd/MM/YYY");

  const handleRole = (role) => {
    if (role === "admin") return "Administrador";
    if (role === "attendant") return "Tratador";
    return "Consumidor";
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 routes pb-5">
           
            <div className="d-flex flex-row align-items-center">
              <h1>Usuários cadastrados</h1>
            </div>

            <div className="d-flex justify-content-between mx-4  mt-2">
            <div className="flex-grow-1 px-4">
              <input
                className="form-control mb-2"
                placeholder="Filtrar pelo nome"
                type="text"
                name="search"
                value={input}
                onChange={(e) => handleSearch(e)}
              />
            </div>

            <div  className="flex-grow-1 px-4">
            <select value={userType} onChange={(e) => handleType(e)} defaultValue="" className="form-control mb-2">
<option value="">Tipo</option>
  <option value="attendant">Tratador</option>
  <option value="client">Consumidor</option>
  <option value="admin">Admin</option>
</select>
</div>
<div  className="flex-grow-1 px-4">
  De   <DatePicker
        selected={startDate}
        onChange={(date) => { setStartDate(date); handlePeriod({startDate : date}) } }
        selectsStart
        startDate={startDate}
        endDate={endDate}
        className="form-control mb-2"
      /> 

&nbsp; a &nbsp;  
       <DatePicker
        selected={endDate}
        onChange={(date) => { setEndDate(date); handlePeriod({endDate : date})} }
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        className="form-control mb-2"
      />


           {/*   <select onChange={(e) => handlePeriod(e)} defaultValue="" className="form-control mb-2">
<option value="">Perfil criado em</option>
{months.map((month) => (
  <option value={month}>{moment(month).format("MMMM [de] YYYY")}</option>
))}
</select> */}
              </div>

              <div  className="px-4">

             {/* <CsvDownloader datas={exportableUsers}  filename={'user_export_'+ current_date} separator=";" columns={columns} bom="true">
<button className='btn btn-primary' id="generatereport">
            Exportar CSV  </button>
</CsvDownloader> */}

<ExcelFile filename={'user_export_'+ current_date} element={<button className='btn btn-primary' id="generatereport">
Exportar </button>}>
  
 <ExcelSheet data={exportableUsers} name="Relatório de check-ins">
 
        <ExcelColumn label="Nome" value="name"/>
        <ExcelColumn label="app_role" value="role"/>
        <ExcelColumn label="E-mail" value="email"/>
        <ExcelColumn label="Telefone" value="whatsapp"/>
        <ExcelColumn label="Endereço" value="address.street"/>
        <ExcelColumn label="Número" value="address.addressNumber"/>
        <ExcelColumn label="Cidade" value="address.city"/>
        <ExcelColumn label="Estado" value="address.state"/>
        <ExcelColumn label="'address.zip" value="CEP"/>
        <ExcelColumn label="app_user_created" value="createdAt"/>
        <ExcelColumn label="app_user_modified" value="updatedAt"/>
        <ExcelColumn label="app_optin" value="news.acceptance"/>
        <ExcelColumn label="app_optin_time" value="news.updatedAt"/>
        <ExcelColumn label="Piscina 1" value="pools.0.title"/>
        <ExcelColumn label="Piscina 1 — capacidade" value="pools.0.capacity"/>
        <ExcelColumn label="Piscina 1 — material" value="pools.0.description"/>

        <ExcelColumn label="Piscina 2" value="pools.1.title"/>
        <ExcelColumn label="Piscina 2 — capacidade" value="pools.1.capacity"/>
        <ExcelColumn label="Piscina 2 — material" value="pools.1.description"/>

        <ExcelColumn label="Piscina 3" value="pools.2.title"/>
        <ExcelColumn label="Piscina 3 — capacidade" value="pools.2.capacity"/>
        <ExcelColumn label="Piscina 3 — material" value="pools.2.description"/>

        <ExcelColumn label="Piscina 4" value="pools.3.title"/>
        <ExcelColumn label="Piscina 4 — capacidade" value="pools.3.capacity"/>
        <ExcelColumn label="Piscina 4 — material" value="pools.3.description"/>

        <ExcelColumn label="Piscina 5" value="pools.4.title"/>
        <ExcelColumn label="Piscina 5 — capacidade" value="pools.4.capacity"/>
        <ExcelColumn label="Piscina 5 — material" value="pools.4.description"/>

        <ExcelColumn label="Piscina 6" value="pools.5.title"/>
        <ExcelColumn label="Piscina 6 — capacidade" value="pools.5.capacity"/>
        <ExcelColumn label="Piscina 6 — material" value="pools.5.description"/>

        <ExcelColumn label="Piscina 7" value="pools.6.title"/>
        <ExcelColumn label="Piscina 7 — capacidade" value="pools.6.capacity"/>
        <ExcelColumn label="Piscina 7 — material" value="pools.6.description"/>

        <ExcelColumn label="Piscina 8" value="pools.7.title"/>
        <ExcelColumn label="Piscina 8 — capacidade" value="pools.7.capacity"/>
        <ExcelColumn label="Piscina 8 — material" value="pools.7.description"/>

        <ExcelColumn label="Piscina 9" value="pools.8.title"/>
        <ExcelColumn label="Piscina 9 — capacidade" value="pools.8.capacity"/>
        <ExcelColumn label="Piscina 9 — material" value="pools.8.description"/>

        <ExcelColumn label="Piscina 10" value="pools.9.title"/>
        <ExcelColumn label="Piscina 10 — capacidade" value="pools.9.capacity"/>
        <ExcelColumn label="Piscina 10 — material" value="pools.9.description"/>
  
                 
</ExcelSheet> 
</ExcelFile>


                </div>



              </div>

            {del && (
              <p className="text-success text-center">
                Usuário deletado com sucesso!
              </p>
            )}
            <hr></hr>

            <div className="d-flex justify-content-between mx-4 px-4 mt-2">
              <div>Usuários: {totalUsers}</div>
              <div>Admins: {adminUsers}</div>
              <div>Tratadores: {attendantUsers}</div>
              <div>Consumidores: {clientUsers}</div>

              
            </div>
            <hr></hr>
            <div>

              <div className="d-flex justify-content-between mx-4 px-4 mt-5 text-primary">
                <h4>Nome</h4>
                <h4>Tipo do usuário</h4>
              </div>
              {users &&
                renderUser.map((user, idx) => (
                  <div key={user._id} className="card__users">
                    <div
                      onClick={() => toggleDetail(idx)}
                      className="d-flex justify-content-between mx-4 my-4 px-4 border-bottom border-primary"
                    >
                      <h5 className="m-0">{user.name}</h5>
                      <h6 className="m-0">{handleRole(user.role)}</h6>
                    </div>
                    {user.toggle && (
                      <>
                        <div className="d-flex justify-content-between mx-1 my-4 px-5 text-secondary flex-wrap">
                          <div>
                            <p className="m-0">
                              <span className="font-weight-bold">Email: </span>
                              {user.email ? user.email : "não cadastrado"}
                            </p>
                            <p className="m-0">
                              <span className="font-weight-bold">Celular: </span>
                              {user.whatsapp ? user.whatsapp : "não cadastrado"}
                            </p>
                            {user.role !== "admin" && user.news && (
                              <div className="d-flex align-items-center">
                                <p className="m-0 pr-2">
                                  <span className="font-weight-bold">
                                    News:  
                                   </span> 
                                   {user.news.acceptance === true
                                    ? " sim"
                                    : " não"}
                                </p>
                                <small>
                                  - Atualizado em: 
                                  {timeFormatted(user.news.updatedAt)}
                                </small>
                              </div>
                            )}
                          </div>
                          {user.address ? (
                            <div className="d-flex justify-content-lg-around flex-wrap w-50">
                              <span>
                                <p className="m-0">
                                  <span className="font-weight-bold">End: </span>
                                  {user.address.street}
                                </p>
                                <p className="m-0">
                                  <span className="font-weight-bold">Num: </span>
                                  {user.address.addressNumber}
                                </p>
                              </span>
                              <span>
                                <p className="m-0">
                                  <span className="font-weight-bold">CEP: </span>
                                  {user.address.zip}
                                </p>
                                <p className="m-0">
                                  <span className="font-weight-bold">
                                    Cidade: 
                                   </span> 
                                   {user.address.city}/{user.address.state}
                                </p>
                                <p className="m-0"></p>
                              </span>
                            </div>
                          ) : (
                            <p className="text-secondary">
                              endereço não cadastrado
                            </p>
                          )}

{ user.pools.length > 0 ? (
   <div className="w-100">
                   
                    <span className="font-weight-bold">Piscinas</span><br /> 
                    <ul>{user.pools.map((pool) => (
  <> 
                <li>{pool.title}: {pool.capacity}.000 litros,  {pool.description}</li>
                  </>
))}</ul> 
                    </div>
                   ) : (<></>) }



                          <div className="d-flex align-items-center flex-md-column flex-lg-row">
                            <Link
                              to={`/editUser/${user._id}`}
                              className="btn btn-info mr-1 mb-md-2 mb-lg-0"
                            >
                              Editar
                            </Link>
                            {user._id !== '5f0f6c1f059012e80242bc4f' ? (
                              <button
                                className="btn btn-danger"
                                onClick={() => showAlert(idx)}
                                // onClick={() => deleteUsers(user._id)}
                              >
                                Deletar
                              </button>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </div>
                        {user.delAlert && (
                          <>
                            <div
                              className="alert alert-danger d-flex justify-content-between align-itens-center"
                              role="alert"
                            >
                              Deseja realmente deletar o usuário: {user.name}
                              <div>
                                <button
                                  className="btn btn-outline-danger btn-sm mr-3"
                                  type="button"
                                  onClick={()=> deleteUsers(user._id)}
                                >
                                  Deletar
                                </button>
                                <button
                                  className="btn btn-outline-secondary btn-sm"
                                  type="button"
                                  onClick={() => showAlert(idx)}
                                >
                                  Cancelar
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
