import React, { useState, useCallback, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";

const EditUser = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [street, setStreet] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  // const [address, setAddress] = useState({});
  const [whatsapp, setWhatsapp] = useState("");
  const [news, setNews] = useState();
  const [updateThen, setUpdateThen] = useState(false);
  const [updateCatch, setUpdateCatch] = useState(false);
  const [loading, setLoading] = useState(true);

  const getUser = useCallback(() => {
    const { id } = props.match.params;
    api
      .get(`/api/users/${id}`)
      .then(({ data }) => {
        setName(data.name);
        setEmail(data.email);
        setRole(data.role);
        setWhatsapp(data.whatsapp);
        setNews(data.news.acceptance);
        if (data.address) {
          setStreet(data.address.street);
          setAddressNumber(data.address.addressNumber);
          setZip(data.address.zip);
          setCity(data.address.city);
          setState(data.address.state);
        }
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [props.match.params]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const updateUser = (e) => {
    // const addressObj = { addressNumber, city, state, street, zip };
    // setAddress(addressObj);
    e.preventDefault();
    const { id } = props.match.params;
    api
      .patch(`api/users/edit/${id}`, {
        name,
        email,
        role,
        address: { addressNumber, city, state, street, zip },
        whatsapp,
        news: { acceptance: news, updatedAt: new Date() },
      })
      .then(() => setUpdateThen(true))
      .catch((error) => {
        setUpdateCatch(true);
        console.log(error);
      });
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
            <div className="container-fluid m-3 routes">
              <h2> Editar usuário</h2>
              {updateThen && <Redirect to={"/users"} />}
              {updateCatch && "Não foi possível atualizar usuário"}
              <hr></hr>
              <form encType="multipart/form-data" onSubmit={(e) => updateUser(e)}>
                <>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Nome</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                    <div className="col-md-3">
                      <label>Role</label>
                      <p className="form-control">{role}</p>
                    </div>
                    <div className="col-md-3">
                      <label>News</label>
                      <select
                        id="inputTypeEdit"
                        name="type"
                        className="form-control"
                        onChange={(e) => setNews(e.target.value)}
                        value={news}
                      >
                        <option value={false}>Não</option>
                        <option value={true}>Sim</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label>Celular</label>
                      <input
                        className="form-control"
                        name="whatsapp"
                        onChange={(e) => setWhatsapp(e.target.value)}
                        value={whatsapp}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Endereço</label>
                      <input
                        type="text"
                        className="form-control"
                        name="street"
                        onChange={(e) => setStreet(e.target.value)}
                        value={street}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Número</label>
                      <input
                        type="text"
                        className="form-control"
                        name="addressNumber"
                        onChange={(e) => setAddressNumber(e.target.value)}
                        value={addressNumber}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label>CEP</label>
                      <input
                        type="text"
                        className="form-control"
                        name="zip"
                        onChange={(e) => setZip(e.target.value)}
                        value={zip}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Cidade</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Estado</label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                      />
                    </div>
                  </div>
                </>
                <button className="btn btn-primary">Salvar</button>
                <Link to={"/users"} className="btn btn-info ml-3">
                  Voltar
              </Link>
              </form>
            </div>
          )}
      </div>
    </div>
  );
};

export default EditUser;
