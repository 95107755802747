import jwtDecode from "jwt-decode";

export const TOKEN_KEY = "secret";

export const isAuthenticated = (role) => {
  const token =  localStorage.getItem(TOKEN_KEY);
  if (token !== null) {
    const tokenPayload = jwtDecode(token);
    // console.log(tokenPayload)
    return tokenPayload.role.includes(role);
  } else {
    return false;
  }
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token !== null) {
    return token
  } else {
    return false
  }
} 

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const getUser = () => jwtDecode(getToken());
