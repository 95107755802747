import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import CardProduct from "../../../components/CardProduct";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader/";

const Products = () => {
  const [rate, setRate] = useState([]);
  const [purify, setPurify] = useState([]);
  const [protect, setProtect] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [delThen, setDelThen] = useState(false);
  const [delCatch, setDelCatch] = useState(false);
  const [loading, setLoading] = useState(true);

  const getAllProducts = useCallback(() => {
    const requests = [
      api.get("/api/products?type=rate").then((response) => response.data),
      api.get("/api/products?type=purify").then((response) => response.data),
      api.get("/api/products?type=protect").then((response) => response.data),
      api.get("/api/products?type=solutions").then((response) => response.data),
    ];

    Promise.all(requests)
      .then(([rate, purify, protect, solutions]) => {
        setRate(rate);
        setPurify(purify);
        setProtect(protect);
        setSolutions(solutions);
        setLoading(false);
        setDelThen(false);
        setDelCatch(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const deleteProduct = (id) => {
    // console.log(id);
    api
      .delete(`/api/products/delete/${id}`)
      .then(() => {
        setDelThen(true);
        getAllProducts();
      })
      .catch((err) => {
        setDelCatch(SVGComponentTransferFunctionElement);
        console.log(err);
      });
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 pb-3 routes">
            <div className="d-flex flex-row align-items-center">
              <h1>Produtos cadastrados</h1>
              <Link
                to="/addProduct"
                type="button"
                className="btn btn-primary ml-3"
              >
                Cadastrar novo produto
              </Link>
            </div>
            {delThen && <p>Produto deletado com sucesso</p>}
            {delCatch && <p>Não foi possível deletar produto</p>}
            <hr></hr>
            <div>
              <h2>Avaliar</h2>
              <p>
                Neste passo vamos avaliar e ajustar, se necessário, os
                parâmetros da água da piscina.
              </p>
              <div className="row m-1">
                {rate.length > 0 ? (
                  rate.map((product) => (
                    <CardProduct
                      product={product}
                      key={product._id}
                      deleteProduct={deleteProduct}
                    />
                  ))
                ) : (
                  <p>sem produtos cadastrados</p>
                )}
              </div>
            </div>
            <div>
              <h2>Purificar</h2>
              <p>
                Este passo é indispensável para manter uma piscina saudável e
                própria para uso. Nele são eliminados as bactérias e fungos
                causadores de doenças.
              </p>
              <div className="row m-1">
                {purify.length > 0 ? (
                  purify.map((product) => (
                    <CardProduct
                      product={product}
                      key={product._id}
                      deleteProduct={deleteProduct}
                    />
                  ))
                ) : (
                  <p>sem produtos cadastrados</p>
                )}
              </div>
            </div>
            <div>
              <h2>Proteger</h2>
              <p>
                Neste passo vamos proteger a beleza da água, mantendo-a sempre
                cristalina.
              </p>
              <div className="row m-1">
                {protect.length > 0 ? (
                  protect.map((product) => (
                    <CardProduct
                      product={product}
                      key={product._id}
                      deleteProduct={deleteProduct}
                    />
                  ))
                ) : (
                  <p>sem produtos cadastrados</p>
                )}
              </div>
            </div>
            <div>
              <h2>Soluçōes</h2>
              <p>
                Para emergência, em situações inesperadas, recupere a água da
                piscina com a linha de soluções.
              </p>
              <div className="row m-1">
                {solutions.length > 0 ? (
                  solutions.map((product) => (
                    <CardProduct
                      product={product}
                      key={product._id}
                      deleteProduct={deleteProduct}
                    />
                  ))
                ) : (
                  <p>sem produtos cadastrados</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Products;
