import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../services/auth';

const Navbar = ({ logoutBtn = true}) => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light d-flex justify-content-between navbar__style'>
        <img src={'/images/logo-hth.png'} alt='logo-hth' style={{width: 150}} className='ml-2 pb-2'/>
      {logoutBtn && (
        <Link className='mr-4 btn btn-primary' to={'/'} onClick={logout}>Sair</Link>
      )}
    </nav>
  );
};

export default Navbar;
