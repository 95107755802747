import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";

const AddNews = () => {
  const [category, setCategory] = useState("summer");
  const [imageUrl, setImageUrl] = useState(null);
  const [originalName, setOriginalName] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [longTitle, setLongTitle] = useState("");
  const [description, setDescription] = useState("");
  const [group, setGroup] = useState("both");
  const [text, setText] = useState("");
  const [postThen, setPostThen] = useState(false);
  const [postCatch, setPostCatch] = useState(false);

  const handleFormSubmit = (event) => {
    /*console.log(
      category,
      imageUrl,
      shortTitle,
      longTitle,
      description,
      group,
      text
    );*/
    event.preventDefault();
    api
      .post("/api/news", {
        category,
        image: imageUrl,
        shortTitle,
        longTitle,
        description,
        group,
        text,
      })
      .then(() => setPostThen(true))
      .catch((error) => {
        setPostCatch(true);
        console.log(error);
      });
  };

  const handleFileChange = (event) => {
    const uploadData = new FormData();
    uploadData.append("imageUrl", event.target.files[0]);

    console.log(event.target.files[0])

    api
      .post("/api/news/upload", uploadData)
      .then((response) => {
        console.log(response);
        setImageUrl(response.data.secure_url);
        setOriginalName(response.data.originalName);
      })
      .catch((error) => console.log(error));
  };

  const handleCategory = (value) => {
    if (value === "management") {
      setCategory("management");
      setGroup("attendant");
    } else {
      setCategory(value);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        <div className="container-fluid m-3 pb-3 routes">
          <h2>Cadastrar um novo conteúdo</h2>
          {postCatch && (
            <p className="text-danger">
              Não foi possível cadastrar um novo conteúdo.
            </p>
          )}
          <hr></hr>
          <form
            onSubmit={(e) => handleFormSubmit(e)}
            encType="multipart/form-data"
          >
            <div>
              <div className="form-group">
                <label>Título curto</label>
                <input
                  type="text"
                  required="true"
                  className="form-control"
                  name="shortTitle"
                  maxLength="40"
                  onChange={(e) => setShortTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Título Interna</label>
                <input
                  type="text"
                  required="true"
                  className="form-control"
                  name="longTitle"
                  onChange={(e) => setLongTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Descrição</label>
                <input
                  type="text"
                  required="true"
                  className="form-control"
                  maxLength="85"
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Imagens</label>
                <input
                  type="file"
                  required="true"
                  className="form-control"
                  id="inputimage"
                  name="imageUrl"
                  onChange={(e) => { handleFileChange(e); }}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">
                    Aguarde enquanto a imagem carrega:{" "}
                  </span>
                </span>
                <span className="file-name">
                  {imageUrl && (
                    <div className="preview">
                      <div className="preview__card" key={originalName}>
                        <img
                          className="preview__img"
                          src={imageUrl}
                          alt="product"
                        />
                        <button
                          className="btn__icon"
                          type="button"
                          onClick={() => setImageUrl(null)}
                        >
                          <img src="/images/trash.png" alt="trash" />
                        </button>
                      </div>
                    </div>
                  )}
                </span>
              </div>
              <div className="form-group">
                <label>Texto</label>
                <textarea
                  type="text"
                  required="true"
                  className="form-control"
                  name="description"
                  rows="10"
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <div className="form-group col-md-3">
                <label>Categoria</label>
                <select
                  type="text"
                  className="form-control"
                  name="category"
                  onChange={(e) => handleCategory(e.target.value)}
                >
                  <option value="summer">Verão hth®</option>
                  <option value="winter">Inverno hth®</option>
                  <option value="health">Saúde hth®</option>
                  <option value="purify">Purificação hth®</option>
                  <option value="management">Gestão hth®</option>
                </select>
              </div>
              <div className="form-group col-md-3">
                <label>Destinatário</label>
                <select
                  type="text"
                  className="form-control"
                  name="group"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option value="both">Tratador e cliente</option>
                  <option value="attendant">Tratador</option>
                  <option value="client">Cliente</option>
                </select>
              </div>
            </div>
            <button className="btn btn-primary mb-5" type="submit">
              Salvar
            </button>
            <Link to={"/news"} className="btn btn-info ml-3 mb-5">
              Voltar
            </Link>
            {postThen && <Redirect to={"/news"} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNews;
