import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import api_site from "../../../services/api_site";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";

const Shops = () => {
  const [stores, setStores] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  
  var allData = [];
  
  var numberOfRequests = 1;

// Create the function for API Call 
function fetchData(apiUrl){ 
  api_site
      .get(apiUrl)
      .then(({ data }) => {
	
		allData.push.apply(allData, data.shops.data); 

		apiUrl = data.shops['next_page_url'];
		
		console.log(data);
		
		console.log(apiUrl);
		
		var lastPage = data.shops['last_page']
		
      // Check next API url is empty or not, if not empty call the above function 
      if (apiUrl != '' && apiUrl != null && numberOfRequests <= lastPage ) {

		fetchData(apiUrl);
	  	numberOfRequests = numberOfRequests + 1;

	  	console.log(numberOfRequests); 

      }  else {
	  	
	  	  setStores(allData);
	  	  console.log(allData);
	  	  setLoading(false);
	  	  allData = [];
	  	}
 

      })
      .catch((error) => console.log(error));
}

  const getAllStores = useCallback(() => {
	
	fetchData("/api/" + process.env.REACT_APP_API_SITE_TOKEN + "/shops?qtd=1500");
	
  }, []);

//useEffect(() => {	  
//    getAllStores();
//}, [getAllStores]);

  const handleSearch = (event) => {
	
	if (event.key === 'Enter') {
      const { value } = event.target;
    setInput(value.toLowerCase());
    setLoading(true);
    getAllStores(input);
    }
      };

  const renderShops = (value) => {
    const storesCity = stores.filter(
      (store) => store.city && store.city.toLowerCase().includes(value)
    );
    return storesCity.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  };

  const handleDelete = (id) => {
    console.log(id);
    api.delete(`api/shops/delete/${id}`).then(console.log).catch(console.log);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        <div className="container-fluid m-3 pb-3 routes">
          <div className="d-flex flex-row align-items-center">
            <h1>Lojas cadastradas</h1>
          </div>
          <div>
            <input
              className="form-control mb-2"
              placeholder="Digite uma cidade e pressione [Enter]"
				onKeyDown={(e) => handleSearch(e)}
            />
          </div>
          <hr></hr>
          <div>
            {loading ? (
              <Loader />
            ) : (
              input &&
              renderShops(input).map((store, idx) => (
                <div
                  key={store._id}
                  className="p-3 border-bottom border-primary"
                >
                  <div className="pb-3">
                    <h5 className="p-0 m-0">{store.name}</h5>
                    <small>CNPJ: {store.cnpj}</small>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <div className="text-secondary">
                      <p className="p0 m-0">
                        {store.address}, {store.number}
                        <span> - {store.neighborhood}</span>
                      </p>
                      <p>
                        {store.city} <span>/ {store.state}</span>
                      </p>
                      <div className="d-flex flex-row align-items-center">
                        <p className="pr-5">
                          <span className="font-weight-bold">Tel.:</span>{" "}
                          {store.phone ? store.phone : "não cadastrado"}
                        </p>
                        <p className="pr-5">
                          <span className="font-weight-bold">Whatsapp.:</span>{" "}
                          {store.whatsapp ? store.whatsapp : "não cadastrado"}
                        </p>
                        <p>
                          <span className="font-weight-bold">Status:</span>{" "}
                          {store.active ? "Loja ativa" : "Loja inativa"}
                        </p>
                      </div>
                    </div>
                    <div>

                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shops;
