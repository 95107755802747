import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Loader from "../../../components/Loader";
import Autocomplete from 'react-google-autocomplete';

import api from '../../../services/api';
import estados from "../../../UF.json";

const AddShop = () => {
  const [name, setName] = useState("");
  const [cnpj, setCNPJ] = useState("");
  const [active, setActive] = useState(true);
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighbourhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [contact, setContact] = useState("");
  const [phone, setPhone] = useState("");
  const [cell_phone, setCellphone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [coordinates, setCoordinates] = useState([]);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();

    let location = {
      type: "Point",
      coordinates
    };

    let newStore = {
      name,
      cnpj,
      active,
      address,
      number,
      complement,
      neighborhood,
      city,
      state,
      zipcode,
      email,
      website,
      contact_name: contact,
      phone,
      cell_phone,
      whatsapp,
      location
    }

    api.post("/api/shops/new", newStore)
      .then(response => {
        setLoading(false);
        history.push("/shops");
      })
      .catch(console.log)
  }

  const handleGeoCoding = (match) => {
    let { lat, lng } = match.geometry.location;
    let latitude = lat();
    let longitude = lng();

    let coords = [
      longitude,
      latitude
    ];


    let longAddress = match.address_components[0].long_name;
    let longNeighbourhood = match.address_components[1].long_name;
    let longCity = match.address_components[2].long_name;
    let longUf = match.address_components[3].short_name;

    if (match.address_components[5]) {
      let longZip = match.address_components[5].long_name;
      setZip(longZip);
    }

    setCoordinates(coords);
    setAddress(longAddress);
    setNeighbourhood(longNeighbourhood);
    setCity(longCity)
    setState(longUf)
  }

  return (
    <>
      <div>
        <Navbar />
        <div className="d-flex flex-row sidebar">
          <Sidebar />
          {
            loading ?
              <Loader /> :
              <div className="container-fluid m-3 pb-3 routes">
                <div className="d-flex flex-row align-items-center">
                  <h1>Cadastrar nova loja</h1>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>CNPJ</label>
                    <input
                      type="text"
                      name="cnpj"
                      className="form-control"
                      onChange={e => setCNPJ(e.target.value)}
                      value={cnpj}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Status</label>
                    <select
                      id="inputTypeEdit"
                      name="active"
                      className="form-control"
                      onChange={e => setActive(e.target.value)}
                    >
                      <option value={true}>Ativa</option>
                      <option value={false}>Inativa</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-5">
                    <label>Endereço</label>
                    <Autocomplete
                      className="form-control"
                      onPlaceSelected={local => handleGeoCoding(local)}
                      onChange={e => setAddress(e.target.value)}
                      types={['address']}
                      componentRestrictions={{ country: "br" }}
                      value={address}
                      name="address"
                      placeholder="Endereço"
                    />
                  </div>
                  <div className="col-md-2">
                    <label>Número</label>
                    <input
                      type="number"
                      className="form-control"
                      name="number"
                      onChange={(e) => setNumber(e.target.value)}
                      value={number} />
                  </div>
                  <div className="col-md-2">
                    <label>Complemento</label>
                    <input
                      type="number"
                      className="form-control"
                      name="complement"
                      onChange={(e) => setComplement(e.target.value)}
                      value={complement} />
                  </div>
                  <div className="col-md-3">
                    <label>Bairro</label>
                    <input
                      type="text"
                      className="form-control"
                      name="neighborhood"
                      onChange={(e) => setNeighbourhood(e.target.value)}
                      value={neighborhood} />
                  </div>

                </div>{/* form row */}
                <div className="form-row">
                  <div className="col-md-6">
                    <label>Cidade</label>
                    <input
                      placeholder="cidade"
                      type="text"
                      className="form-control"
                      name="city"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>UF</label>
                    <select
                      placeholder="UF"
                      id="inputTypeEdit"
                      name="state"
                      className="form-control"
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                    >
                      {
                        estados.UF.map((estado, index) =>
                          <option
                            key={index}
                            value={estado.sigla}>
                            {estado.sigla}
                          </option>)
                      }
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>CEP</label>
                    <input
                      type="text"
                      className="form-control"
                      name="zipcode"
                      onChange={(e) => setZip(e.target.value)}
                      value={zipcode} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-4">
                    <label>Email</label>
                    <input
                      placeholder="contato@email.com"
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Website</label>
                    <input
                      placeholder="http://www.websitedaloja.com.br"
                      type="text"
                      className="form-control"
                      name="website"
                      onChange={(e) => setWebsite(e.target.value)}
                      value={website}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Contato (Nome)</label>
                    <input
                      placeholder="nome do contato"
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={(e) => setContact(e.target.value)}
                      value={contact}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-md-4">
                    <label>Telefone</label>
                    <input
                      placeholder=""
                      type="text"
                      className="form-control"
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Telefone móvel</label>
                    <input
                      placeholder=""
                      type="text"
                      className="form-control"
                      name="cell_phone"
                      onChange={(e) => setCellphone(e.target.value)}
                      value={cell_phone}
                    />
                  </div>
                  <div className="col-md-4">
                    <label>Whatsapp</label>
                    <input
                      placeholder=""
                      type="text"
                      className="form-control"
                      name="whatsapp"
                      onChange={(e) => setWhatsapp(e.target.value)}
                      value={whatsapp}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-primary mt-3"
                  onClick={e => handleSubmit(e)}
                >
                  Salvar
                </button>
              </div>
          }
        </div>
      </div>
    </>
  )
}

export default AddShop;
