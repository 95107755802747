import React from 'react';
import { Link } from 'react-router-dom';

const CardProduct = ({ product, deleteProduct }) => {
  return(
  <div className="col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5" style={{height: 30 + "rem"}} >
    <div className="card">
      <img className="card-img-top" src={product.images[0]} alt={product.title}/>
      <div className="card-body d-flex flex-column justify-content-between">
        <Link  to={`/Product/${product._id}`}><h4>{product.title}</h4></Link>
        <div className="d-flex justify-content-around">
          <button onClick={() => deleteProduct(product._id)} className="btn btn-danger">Deletar</button>
          <Link to={`/editProduct/${product._id}`} className="btn btn-info">Editar</Link>
        </div>
      </div>
    </div>
  </div>
  );
};

export default CardProduct;
