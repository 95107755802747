import React, { useState } from "react";

import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";

import Loader from "../../components/Loader";


const Rdstation = () => {

  const [loading, setLoading] = useState(false);

  return (
    <div>
    
      <div className="d-flex flex-row sidebar">
       
        {loading ? (
          <Loader />
        ) : (
          <>RDSTATION</>
        )}
      </div>
    </div>
  );
};

export default Rdstation;
