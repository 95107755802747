import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";

const ProductDetail = (props) => {
  const [title, setTitle] = useState("");
  const [expiration, setExpiration] = useState("");
  const [type, setType] = useState("");
  const [operation, setOperation] = useState("");
  const [packages, setPackages] = useState("");
  const [images, setImages] = useState("");
  const [loading, setLoading] = useState(true);

  const getProduct = useCallback(() => {
    const { id } = props.match.params;
    api
      .get(`/api/products/${id}`)
      .then(({ data }) => {
        setTitle(data.title);
        setExpiration(data.expiration);
        setType(data.type);
        setOperation(data.operation);
        setPackages(data.packages);
        setImages(data.images);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [props.match.params]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const handleType = (type) => {
    if (type === "rate") return "Avaliar";
    if (type === "purify") return "Purificar";
    if (type === "protect") return "Proteger";
    return "Soluções";
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3">
            <div className="col-xs-6 col-sm-6 col-md-12 col-lg-12">
              <h2 className="text-primary">{title}</h2>
              <p>
                <span className="font-weight-bold">Validade: </span>
                {expiration}
              </p>
              <p>
                <span className="font-weight-bold">Atuação: </span>
                {operation}
              </p>
              <p>
                <span className="font-weight-bold">Tipo: </span>
                {handleType(type)}
              </p>
              <p>
                <span className="font-weight-bold">Disponível em: </span>
                {packages}
              </p>
              <div>
                <p className="font-weight-bold">Imagens: </p>
                {images &&
                  images.map((img) => (
                    <img src={img} alt="preview" style={{ width: 150 }} />
                  ))}
              </div>
            </div>
            <div className="d-flex justify-content-start mt-5">
              <Link to={"/products"} className="btn btn-info">
                Voltar
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
