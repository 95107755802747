import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";

import { parseISO, format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Loader from "../../../components/Loader";

const Guide = () => {
  const [guide, setGuide] = useState([]);
  const [loading, setLoading] = useState(true);

  const getGuide = useCallback(() => {
    api
      .get("/api/guides")
      .then((response) => {
        const newData = response.data.map((item) => {
          item.delAlert = false;
          return item;
        });
        setGuide(newData);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getGuide();
  }, [getGuide]);

  const timeFormatted = (date) =>
    format(zonedTimeToUtc(parseISO(date), "America/Sao_Paulo"), "dd/MM/YYY");

  const showAlert = (idx) => {
    const temp = guide.map((item, i) => {
      if (idx === i) {
        item.delAlert = !item.delAlert;
      }
      return item;
    });
    setGuide(temp);
  };

  const deleteGuide = (id) => {
    api
      .delete(`/api/guides/delete/${id}`)
      .then(() => getGuide())
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 pb-3 routes">
            <div className="d-flex flex-row align-items-center">
              <h1>Guia prático</h1>
              <Link
                to="/addGuide"
                type="button"
                className="btn btn-primary ml-3"
              >
                Cadastrar novo conteúdo
              </Link>
            </div>
            <hr></hr>
            {guide &&
              guide.map((item, idx) => (
                <div className=" border-bottom border-primary" key={idx}>
                  <div
                    key={item._id}
                    className="p-3 text-secondary d-flex flex-row justify-content-between align-items-center"
                  >
                    <div>
                      <p>
                        <span className="font-weight-bold">Criado em: </span>
                        {timeFormatted(item.createdAt)}
                      </p>
                      <h3 className="text-dark py-2">{item.title}</h3>
                      <p>
                        <span className="font-weight-bold">Link: </span>
                        <a href={item.link}>{item.link}</a>
                      </p>
                    </div>
                    <div className="d-flex align-items-center flex-md-column flex-lg-row">
                      <Link
                        to={`/editGuide/${item._id}`}
                        className="btn btn-info mr-1 mb-md-2 mb-lg-0"
                      >
                        Editar
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => showAlert(idx)}
                      >
                        Deletar
                      </button>
                    </div>
                  </div>
                  {item.delAlert && (
                    <>
                      <div
                        className="alert alert-danger d-flex justify-content-between align-itens-center"
                        role="alert"
                      >
                        Deseja realmente deletar essa informação do guia
                        prático?
                        <div>
                          <button
                            className="btn btn-outline-danger btn-sm mr-3"
                            type="button"
                            onClick={() => deleteGuide(item._id)}
                          >
                            Deletar
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            type="button"
                            onClick={() => showAlert(idx)}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Guide;
