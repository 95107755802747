import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../../../services/api';

import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';

const AddGuide = () => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [originalName, setOriginalName] = useState('');
  const [postThen, setPostThen] = useState(false);
  const [postCatch, setPostCatch] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    api.post("/api/guides", {
      title,
      link,
      image: imageUrl
    })
      .then(() => setPostThen(true))
      .catch(error => {
        setPostCatch(true)
        console.log(error)
      });
  };

  const handleFileChange = (event) => {
    const uploadData = new FormData();
    uploadData.append("imageUrl", event.target.files[0]);

    api.post("/api/guides/upload", uploadData)
      .then((response) => {
        setImageUrl(response.data.secure_url)
        setOriginalName(response.data.originalName)
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Navbar/>
      <div  className="d-flex flex-row sidebar">
        <Sidebar />
        <div className='container-fluid m-3 pb-3 routes'>
          <h2>Cadastrar um novo conteúdo</h2>
          {postCatch && <p className="text-danger">Não foi possível cadastrar um novo conteúdo.</p>}
          <hr></hr>
          <form onSubmit={(e) => handleFormSubmit(e)} encType="multipart/form-data">
          <div>
            <div className="form-group">
              <label>Título</label>
              <input type="text" required="true" className="form-control" name="title" maxLength="40" onChange={(e) => setTitle(e.target.value)}/>
            </div>
            <div className="form-group">
              <label>Imagens</label>
              <input type="file" required="true" className="form-control" id="inputimage" name="imageUrl" onChange={(e) => handleFileChange(e)} />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fas fa-upload"></i>
                </span>
                <span className="file-label">Aguarde enquanto a imagem carrega: </span>
              </span>
              <span className="file-name">
                {
                  imageUrl &&  (
                  <div className="preview">
                    <div className="preview__card" key={originalName}>
                      <img className="preview__img" src={imageUrl} alt='product' />
                      <button className="btn__icon" type='button' onClick={() => setImageUrl(null)}>
                        <img src='/images/trash.png' alt='trash'/>
                      </button>
                    </div>
                </div>
                  )}
              </span>
            </div>
            <div className="form-group">
              <label>Link</label>
              <input type="text" required="true" className="form-control" name="link" onChange={(e) => setLink(e.target.value)}/>
            </div>
          </div>
          <button className="btn btn-primary" type="submit">Salvar</button>
            <Link to={'/guide'} className="btn btn-info ml-3">Voltar</Link>
            {postThen && <Redirect to={'/guide'} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddGuide;