import axios from "axios";
import { getToken } from "./auth";

const api_site = axios.create({
  baseURL: process.env.REACT_APP_API_SITE_URL
});

api_site.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api_site;