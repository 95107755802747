import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../../../services/api';

import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';

const AddNotifications = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [link, setLink] = useState('');
  const [group, setGroup] = useState('both');
  const [postThen, setPostThen] = useState(false);
  const [postCatch, setPostCatch] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    api.post("/api/notifications", {
      title,
      content,
      link,
      group
      })
      .then(() => setPostThen(true))
      .catch(error => {
        setPostCatch(true)
        console.log(error)
      });
  };

  return (
    <div>
      <Navbar/>
      <div  className="d-flex flex-row sidebar">
        <Sidebar />
        <div className="container-fluid m-3 routes">
          <h2>Cadastrar um novo alerta</h2>
          { postCatch && <p className="text-danger">Não foi possível cadastrar um novo alerta.</p>}
          <hr></hr>
          <form onSubmit={(e) => handleFormSubmit(e)} encType="multipart/form-data">
            <div>
              <div className="form-group">
                <label>Título</label>
                <input type="text" required="true" className="form-control" name="title" maxLength="40" onChange={(e) => setTitle(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Descrição</label>
                <textarea type="text" required="true" className="form-control" rows="2" name="content" maxLength="210" onChange={(e) => setContent(e.target.value)}></textarea>
              </div>
              <div className="form-row">
              <div className="form-group col-md-9">
                <label>Link</label>
                <input type="text" className="form-control" name="link" onChange={(e) => setLink(e.target.value)}/>
              </div>
              <div className="form-group col-md-3">
                <label>Destinatário</label>
                <select type="text" className="form-control" name="group" onChange={(e) => setGroup(e.target.value)}>
                <option value="both">Tratador e cliente</option>
                <option value="attendant">Tratador</option>
                <option value="client">Cliente</option>
                </select>
              </div>
              </div>
            </div>
            <button className="btn btn-primary" type="submit mb-5">Salvar</button>
            <Link to={'/notifications'} className="btn btn-info ml-3">Voltar</Link>
            { postThen && <Redirect to={'/notifications'}/>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNotifications;