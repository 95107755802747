import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import { login } from "../../services/auth";
import apiAxios from "../../services/api";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginResponse = await apiAxios.post("/auth/login", {
        email,
        password,
      });
      if (loginResponse.data.error) {
        setError(loginResponse.data.error);
      } else {
        login(loginResponse.data.token);
        history.push("/products");
      }
    } catch (err) {
      setError("Email ou senha incorretos.");
    }
  };

  return (
    <div className="container d-flex justify-content-center align-item-center">
      <form
        className="d-flex flex-column mt-5 pb-50 w-50"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="border border-secondary p-3 rounded w-100">
          <h4 className="text-primary text-center py-3">Acesse sua conta</h4>
          <div className="form-group mx-5">
            <input
              type="email"
              name="email"
              className="form-control"
              aria-describedby="emailHelp"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="E-mail"
              required
            />
          </div>
          <div className="form-group mx-5">
            <input
              name="password"
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Senha"
              required
            />
          </div>
          <div className="d-flex justify-content-center align-item-center py-3">
            <button type="submit" className="btn btn-primary mx-5 w-100">
              Entrar
            </button>
          </div>
          {error && <p className="text-center text-danger">{error}</p>}
          {/* <p className="text-center">Você é novo aqui?<Link to={'/signup'}> Crie sua conta</Link></p> */}
        </div>
      </form>
    </div>
  );
};

export default Login;
