import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";

import { parseISO, format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Loader from "../../../components/Loader";

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  const getNews = useCallback(() => {
    api
      .get("/api/news")
      .then((response) => {
        setLoading(false);
        const newData = response.data.map((item) => {
          item.delAlert = false;
          return item;
        });
        setNews(newData);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getNews();
  }, [getNews]);

  const timeFormatted = (date) =>
    format(zonedTimeToUtc(parseISO(date), "America/Sao_Paulo"), "dd/MM/YYY");

  const showAlert = (idx) => {
    const temp = news.map((item, i) => {
      if (idx === i) {
        item.delAlert = !item.delAlert;
      }
      return item;
    });
    setNews(temp);
  };

  const deleteNew = (id) => {
    api
      .delete(`/api/news/delete/${id}`)
      .then(() => getNews())
      .catch((error) => console.log(error));
  };

  const handleGroup = (type) => {
    if (type === "attendant") return "Tratador";
    if (type === "client") return "Cliente";
    return "Tratador e Cliente";
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 pb-3 routes">
            <div className="d-flex flex-row align-items-center">
              <h1>Novidades</h1>
              <Link
                to="/addNews"
                type="button"
                className="btn btn-primary ml-3"
              >
                Cadastrar novo conteúdo
              </Link>
            </div>
            <hr></hr>
            {news &&
              news.map((item, idx) => (
                <div className="border-bottom border-primary" key={idx} >
                  <div
                    key={item._id}
                    className="p-3 text-secondary d-flex flex-row justify-content-between align-items-center"
                  >
                    <div>
                      <p>
                        <span className="font-weight-bold">Criado em: </span>
                        {timeFormatted(item.createdAt)}
                      </p>
                      <h3 className="text-dark py-2">{item.shortTitle}</h3>
                      <h5 className="text-dark py-2">{item.longTitle}</h5>
                      <p>
                        <span className="font-weight-bold">Descrição: </span>
                        {item.description}
                      </p>
                      <p>
                        <span className="font-weight-bold">Destinatário: </span>
                        {handleGroup(item.group)}
                      </p>
                      <p>
                        <span className="font-weight-bold">
                          <label>
                            <input
                              type="checkbox"
                              checked={item.highlight === "true" ? true : false}
                            />
                          </label>{" "}
                          Destaque
                        </span>
                      </p>
                    </div>
                    <div className="d-flex align-items-center flex-md-column flex-lg-row">
                      <Link
                        to={`/editNews/${item._id}`}
                        className="btn btn-info mr-1 mb-md-2 mb-lg-0"
                      >
                        Editar
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => showAlert(idx)}
                      >
                        Deletar
                      </button>
                    </div>
                  </div>
                  {item.delAlert && (
                    <>
                      <div
                        className="alert alert-danger d-flex justify-content-between align-itens-center"
                        role="alert"
                      >
                        Deseja realmente deletar esse conteúdo de "Novidades"?
                        <div>
                          <button
                            className="btn btn-outline-danger btn-sm mr-3"
                            type="button"
                            onClick={() => deleteNew(item._id)}
                          >
                            Deletar
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            type="button"
                            onClick={() => showAlert(idx)}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
