import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../../../services/api';

import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';

const AddProduct = () => {
  const [title, setTitle] = useState('');
  const [expiration, setExpiration] = useState('');
  const [type, setType] = useState('rate');
  const [operation, setOperation] = useState('');
  const [packages, setPackages] = useState('');
  const [link, setLink] = useState('');
  const [shop_link, setShop_Link] = useState('');
  const [dosage, setDosage] = useState([]);
  // const [images, setImages] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const [setOriginalName] = useState('');
  const [postThen, setPostThen] = useState(false);
  const [postCatch, setPostCatch] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    api.post("/api/products", {
      title,
      expiration,
      type,
      operation,
      packages,
      link,
      shop_link,
      dosage,
      images: [...imageUrl]
    })
      .then(() => setPostThen(true))
      .catch(error => {
        setPostCatch(true)
        console.log(error)
      });
  };

  const handleFileChange = (event) => {
    const uploadData = new FormData();
    uploadData.append("imageUrl", event.target.files[0]);

    api.post("/api/products/upload", uploadData)
      .then((response) => {
        const images = [...imageUrl, response.data.secure_url]
        setImageUrl(images)
        setOriginalName(response.data.originalName)
      })
      .catch((error) => console.log(error));
      console.log('==>' ,imageUrl)
  };

  const handleDosageField = (id, name, target) => {
    if (dosage.some(item => item.id === id)) {
      let newDosage = dosage;
      newDosage.forEach(item => {
        if (item["id"] === id) {
          item[name] = target;
        }
      });
      setDosage(newDosage);
      // console.log(dosage);
      return;
    }
    let newDosage = {};
    newDosage["id"] = id;
    newDosage[name] = target;
    // console.log(newDosage);
    setDosage([...dosage, newDosage]);
    // console.log(dosage);
  }

  const deleteImage = (idx) => {
    const del = [...imageUrl]
    del.splice(idx, 1);

    setImageUrl(del)
  }

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        <div className="container-fluid m-3 routes">
          <h2>Cadastrar um novo produto</h2>
          {postCatch && <p className="text-danger">Não foi possível cadastrar um novo produto.</p>}
          <hr></hr>
          <form onSubmit={(e) => handleFormSubmit(e)} encType="multipart/form-data">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nome do produto</label>
                <input type="text" required="true" className="form-control" id="inputTitle" name="title" onChange={(e) => setTitle(e.target.value)} />
              </div>
              <div className="form-group col-md-3">
                <label>Validade</label>
                <input type="text" required="true" className="form-control" id="inputExpiration" name="expiration" onChange={(e) => setExpiration(e.target.value)} />
              </div>
              <div className="form-group col-md-3">
                <label>Tipo</label>
                <select id="inputTypeEdit" name="type" className="form-control" onChange={(e) => setType(e.target.value)}>
                  <option value='rate'>Passo 1 Avaliar</option>
                  <option value='purify'>Passo 2 Purificar</option>
                  <option value='protect'>Passo 3 Proteger</option>
                  <option value='solutions'>Passo 4 Soluções</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Dosagem</label>
                <input
                  type="number"
                  className="form-control"
                  name="dosage"
                  onChange={e => handleDosageField(1, "dosage", e.target.value)}
                  step="0.01"
                  min="0"
                />
              </div>
              <div className="form-group col-md-3">
                <label>Unidade</label>
                <select name="measure" className="form-control" onChange={e => handleDosageField(1, "units", e.target.value)}>
                  <option value='g'>gramas</option>
                  <option value='ml'>mililitros</option>
                </select>
              </div>
              <div className="form-group col-md-3">
                <label>Condição</label>
                <input type="text" className="form-control" name="description" onChange={e => handleDosageField(1, "description", e.target.value)} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Dosagem</label>
                <input
                  type="number"
                  className="form-control"
                  name="dosage"
                  onChange={e => handleDosageField(2, "dosage", e.target.value)}
                  step="0.01"
                  min="0"
                />
              </div>
              <div className="form-group col-md-3">
                <label>Unidade</label>
                <select name="measure" className="form-control" onChange={e => handleDosageField(2, "units", e.target.value)}>
                  <option value="" disabled selected hidden>Escolha a unidade</option>
                  <option value='g'>gramas</option>
                  <option value='ml'>mililitros</option>
                </select>
              </div>
              <div className="form-group col-md-3">
                <label>Condição</label>
                <input type="text" className="form-control" name="description" onChange={e => handleDosageField(2, "description", e.target.value)} />
              </div>
            </div>

            <div className="form-group">
              <label>Atuação</label>
              <textarea type="text" required="true" className="form-control" id="inputOperation" rows="1" name="operation" onChange={(e) => setOperation(e.target.value)}></textarea>
            </div>
            <div className="form-group">
              <label>Disponivel em</label>
              <input type="text" required="true" className="form-control" id="inputPackage" name="packages" onChange={(e) => setPackages(e.target.value)} />
            </div>
            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Link do produto</label>
                <input type="text" required="true" className="form-control" id="inputAlert" name="link" onChange={(e) => setLink(e.target.value)} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Link do produto na loja</label>
                <input type="text" required="true" className="form-control" id="inputAlert" name="shop_link" onChange={(e) => setShop_Link(e.target.value)} />
              </div>
            </div>
            <div className="form-group">
              <label>Imagens</label>
              {imageUrl.length < 2 && (
                <>
                <input type="file" className="form-control" id="inputimage" name="imageUrl" onChange={(e) => handleFileChange(e)} />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Aguarde enquanto a imagem carrega: </span>
                </span>
                </>)
              }
              <span className="file-name">
                {/* {originalName && originalName} */}
                <div className="preview">
                {
                  imageUrl.length > 0 &&  imageUrl.map((image, idx) => (
                    <div className="preview__card" key={idx}>
                      <img className="preview__img" src={image} alt='product' />
                      <button className="btn__icon" type='button' onClick={() => deleteImage(idx)}>
                        <img src='/images/trash.png' alt='trash'/>
                      </button>
                    </div>
                  ))
                }
                </div>
              </span>
            </div>
            <button className="btn btn-primary mb-5" type="submit">Salvar</button>
            <Link to={'/products'} className="btn btn-info ml-3 mb-5">Voltar</Link>
            {postThen && <Redirect to={'/products'} />}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;