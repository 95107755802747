import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="bg-light sidebar pt-5 p-3">
      <div className="sidebar__container">
        <div className="py-3">
          <Link to={'/users'} className="sidebar__link"><h5>USUÁRIOS</h5></Link>
        </div>
        <div className="py-3">
          <Link to={'/shops'} className="sidebar__link"><h5>LOJAS</h5></Link>
        </div>
        <div className="py-3">
          <Link to={'/products'} className="sidebar__link"><h5>PRODUTOS</h5></Link>
        </div>
        <div className="py-3">
          <Link to={'/notifications'} className="sidebar__link"><h5>ALERTAS</h5></Link>
        </div>
        <div className="py-3">
          <Link to={'/guide'} className="sidebar__link"><h5>GUIA PRÁTICO</h5></Link>
        </div>
        <div className="py-3">
          <Link to={'/news'} className="sidebar__link"><h5>NOVIDADES</h5></Link>
        </div>
        <div className="py-3">
          <Link to={'/signup'} className="sidebar__link"><h5>CRIAR CONTA</h5></Link>
        </div>
        <div className="py-3">
        <a href="https://api.rd.services/auth/dialog?client_id=5e9ccd26-9bde-49fc-a83b-75264e8c16cb&redirect_uri=https://hth.dev-sa365.com.br/rdstation&state=" className="sidebar__link">
        <h5>RDSTATION</h5>
        </a>
        </div>
      </div>
    </div>
  )
};

export default Sidebar;