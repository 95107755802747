import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";

import { parseISO, format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Loader from "../../../components/Loader";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [delThen, setDelThen] = useState(false);
  const [delCatch, setDelCatch] = useState(false);
  const [loading, setLoading] = useState(true);

  const getNotifications = useCallback(() => {
    api
      .get("/api/notifications")
      .then((response) => {
        const newData = response.data.map((item) => {
          item.delAlert = false;
          return item;
        });
        setNotifications(newData);
        setDelThen(false);
        setDelCatch(false);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const timeFormatted = (date) =>
    format(zonedTimeToUtc(parseISO(date), "America/Sao_Paulo"), "dd/MM/YYY");

  const showAlert = (idx) => {
    const temp = notifications.map((item, i) => {
      if (idx === i) {
        item.delAlert = !item.delAlert;
      }
      return item;
    });
    setNotifications(temp);
  };

  const deleteNotification = (id) => {
    api
      .delete(`/api/notifications/delete/${id}`)
      .then(() => {
        setDelThen(true);
        getNotifications();
      })
      .catch((err) => {
        setDelCatch(true);
        console.log(err);
      });
  };

  const setGroup = (type) => {
    if (type === "attendant") return "Tratadores";
    if (type === "client") return "Clientes";
    return "Tratadores e clientes";
  };

  const handleCheckbox = (idx, status) => {
    const temp = [...notifications];
    temp[idx].active = status;
    setNotifications(temp);
    const id = notifications[idx]._id;
    api
      .patch(`api/notifications/status/${id}`, {
        active: notifications[idx].active,
      })
      .then()
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? (
          <Loader />
        ) : (
          <div className="container-fluid m-3 routes">
            <div className="d-flex flex-row align-items-center">
              <h1>Alertas</h1>
              <Link
                to="/addNotifications"
                type="button"
                className="btn btn-primary ml-3"
              >
                Cadastrar novo alerta
              </Link>
            </div>
            {delThen && (
              <p className="text-success text-center">
                Alerta deletada com sucesso
              </p>
            )}
            {delCatch && (
              <p className="text-danger text-center">
                Não foi possível deletar o alerta
              </p>
            )}
            <hr></hr>
            {notifications &&
              notifications.map((item, idx) => (
                <div className="border-bottom border-primary ">
                  <div
                    key={item._id}
                    className="p-3 text-secondary d-flex flex-row justify-content-between align-items-center"
                  >
                    <div>
                      <p>
                        <span className="font-weight-bold">Criado em: </span>
                        {timeFormatted(item.createdAt)}
                      </p>
                      <h3 className="text-dark py-2">{item.title}</h3>
                      <p>
                        <span className="font-weight-bold">Descrição: </span>
                        {item.content}
                      </p>
                      <p>
                        <span className="font-weight-bold">Link: </span>
                        {item.link ? (
                          <a href={item.link}>{item.link}</a>
                        ) : (
                          "conteúdo não cadastrado"
                        )}
                      </p>
                      <p>
                        <span className="font-weight-bold">Destinatário: </span>
                        {setGroup(item.group)}
                      </p>
                      <p>
                        <span className="font-weight-bold">Status: </span>
                        {item.active
                          ? "Notificação ativa"
                          : "Notificação inativa"}
                      </p>
                    </div>
                    <div>
                      <button
                        className="btn btn-danger mx-1 mb-2"
                        onClick={() => showAlert(idx)}
                      >
                        Deletar
                      </button>
                      {!item.active ? (
                        <button
                          className="btn btn-success mx-1 mb-2"
                          onClick={() => handleCheckbox(idx, !item.active)}
                        >
                          Ativar
                        </button>
                      ) : (
                        <button
                          className="btn btn-info mx-1 mb-2"
                          onClick={() => handleCheckbox(idx, !item.active)}
                        >
                          Desativar
                        </button>
                      )}
                    </div>
                  </div>
                  {item.delAlert && (
                    <>
                      <div
                        className="alert alert-danger d-flex justify-content-between align-itens-center"
                        role="alert"
                      >
                        Deseja realmente deletar essa notificação?
                        <div>
                          <button
                            className="btn btn-outline-danger btn-sm mr-3"
                            type="button"
                            onClick={() => deleteNotification(item._id)}
                          >
                            Deletar
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            type="button"
                            onClick={() => showAlert(idx)}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
