import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Signup from "../pages/Signup";
import Login from "../pages/Login";

import Products from "../pages/Products/MainProducts";
import AddProduct from "../pages/Products/AddProduct";
import EditProduct from "../pages/Products/EditProducts";
import ProductDetail from "../pages/Products/ProductDetail";
import Users from "../pages/Users/UserMain";
import EditUser from "../pages/Users/EditUser";
import Shops from "../pages/Shops/ShopsMain";
import AddShops from "../pages/Shops/AddShops";
import EditShops from "../pages/Shops/EditShops";
import Notifications from "../pages/Notifications/MainNotifications";
import AddNotifications from "../pages/Notifications/AddNotifications";
import Guide from "../pages/Guide/MainGuide";
import AddGuide from "../pages/Guide/AddGuide";
import EditGuide from "../pages/Guide/EditGuide";
import News from "../pages/News/MainNews";
import AddNews from "../pages/News/AddNews";
import EditNews from "../pages/News/EditNews";
import CreatePassword from "../pages/ForgotPassword";

import Terms from "../pages/Terms/Terms";

import Rdstation from "../pages/Rdstation/Rdstation";

import { isAuthenticated } from "../services/auth";

const PrivateRoute = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={props => (isAuthenticated(role) ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)}
  />
);

const Routes = () => (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/forgotPassword/:id" component={CreatePassword} />
      <Route path="/terms" component={Terms}/>
      <Route path="/rdstation" component={Rdstation}/>
      <PrivateRoute role='admin' path="/signup" component={Signup} />
      <PrivateRoute role='admin' path="/users" component={Users} />
      <PrivateRoute role='admin' path="/editUser/:id" component={EditUser} />
      <PrivateRoute role='admin' path="/shops" component={Shops} />
      <PrivateRoute role='admin' path="/addShop" component={AddShops} />
      <PrivateRoute role='admin' path="/editShop/:id" component={EditShops} />
      <PrivateRoute role='admin' path="/products" component={Products} />
      <PrivateRoute role='admin' path="/addProduct" component={AddProduct} />
      <PrivateRoute role='admin' path="/product/:id" component={ProductDetail} />
      <PrivateRoute role='admin' path="/editProduct/:id" component={EditProduct} />
      <PrivateRoute role='admin' path="/notifications" component={Notifications} />
      <PrivateRoute role='admin' path="/addNotifications" component={AddNotifications} />
      <PrivateRoute role='admin' path="/guide" component={Guide} />
      <PrivateRoute role='admin' path="/addGuide" component={AddGuide} />
      <PrivateRoute role='admin' path="/editGuide/:id" component={EditGuide} />
      <PrivateRoute role='admin' path="/news" component={News} />
      <PrivateRoute role='admin' path="/addNews" component={AddNews} />
      <PrivateRoute role='admin' path="/editNews/:id" component={EditNews} />
      <PrivateRoute path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
);

export default Routes;
