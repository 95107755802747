import React, { useState, useEffect, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../../../services/api';

import Sidebar from '../../../components/Sidebar';
import Navbar from '../../../components/Navbar';
import Loader from '../../../components/Loader';

const EditGuide = (props) => {
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [originalName, setOriginalName] = useState('');
  const [postThen, setPostThen] = useState(false);
  const [postCatch, setPostCatch] = useState(false);
  const [imgError, setImgError] = useState(null);
  const [loading, setLoading] = useState(true);


  const getGuide = useCallback(() => {
    const { id } = props.match.params;

    api.get(`/api/guides/${id}`)
      .then(({ data }) => {
        // console.log('guia',data)
        setTitle(data.title)
        setImageUrl(data.image)
        setLink(data.link)
        setLoading(false);
      })
      .catch(error => console.log(error))
  }, [props.match.params]);

  useEffect(() => {
    getGuide()
  }, [getGuide]);

  const updateGuide = (e) => {
    e.preventDefault();
    const { id } = props.match.params;

    if(!imageUrl) {
      setImgError("Insira uma imagem");
      return
    }

    api.patch(`/api/guides/edit/${id}`, {
      title,
      link,
      image: imageUrl
    })
      .then(() => setPostThen(true))
      .catch(error => {
        setPostCatch(true)
        console.log(error)
      })
  }

  const handleFileChange = (event) => {
    const uploadData = new FormData();
    uploadData.append("imageUrl", event.target.files[0]);

    api.post("/api/guides/upload", uploadData)
      .then((response) => {
        setImageUrl(response.data.secure_url)
        setOriginalName(response.data.originalName)
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Navbar/>
      <div  className="d-flex flex-row sidebar">
        <Sidebar />
        {loading ? <Loader /> : (
        <div className='container-fluid m-3 pb-3 routes'>
          <h2>Editar conteúdo</h2>
          {postCatch && <p className="text-danger text-center">Não foi possível editar conteúdo.</p>}
          {imgError && <p className="text-danger text-center">{imgError}</p>}
          <hr></hr>
          <form onSubmit={(e) => updateGuide(e)} encType="multipart/form-data">
            <div>
              <div className="form-group">
                <label>Título</label>
                <input type="text" required="true" className="form-control" name="title" maxLength="40" value={title}  onChange={(e) => setTitle(e.target.value)}/>
              </div>
              <div className="form-group">
                <label>Imagens</label>
                <input type="file" className="form-control" id="inputimage" name="imageUrl" onChange={(e) => handleFileChange(e)} />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Aguarde enquanto a imagem carrega: </span>
                </span>
                <span className="file-name">
                {
                  imageUrl &&  (
                  <div className="preview">
                    <div className="preview__card" key={originalName}>
                      <img className="preview__img" src={imageUrl} alt='product' />
                      <button className="btn__icon" type='button' onClick={() => setImageUrl(null)}>
                        <img src='/images/trash.png' alt='trash'/>
                      </button>
                    </div>
                </div>
                  )}
                </span>
              </div>
              <div className="form-group">
                <label>Link</label>
                <input type="text" required="true" className="form-control" name="link" value={link}  onChange={(e) => setLink(e.target.value)}/>
              </div>
            </div>
            <button className="btn btn-primary" type="submit">Salvar</button>
            <Link to={'/guide'} className="btn btn-info ml-3">Voltar</Link>
            {postThen && <Redirect to={'/guide'} />}
          </form>
        </div>
        )}
      </div>
    </div>
  );
};

export default EditGuide;